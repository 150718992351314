"use strict";

import axios from "axios";

class OAuth {
   constructor(eventBus) {
      eventBus.subscribe("login", this.afterLogin);
   }

   afterLogin = (data) => {
      axios.post(window.location.pathname + "/result", data).then((response) => {
         if (response.status === 200) {
            window.location.href = response.data.redirectUrl;
         }
      });
   }
}

export { OAuth };