"use strict";
// This file is for common functions that are used to show and hide elements on the page
// This file is probably temporary if things get complex enough to warrant a library or framework

export function showAuthenticatingUser() {
   document.getElementById("authenticatingUser").style.display = "block";
}

export function hideAuthenticatingUser() {
   document.getElementById("authenticatingUser").style.display = "none";
}

export function showLoading() {
   document.getElementById("loading").style.display = "flex";
}

export function hideLoading() {
   document.getElementById("loading").style.display = "none";
}

export function showLoginForm() {
   hideLoading();
   hideAuthenticatingUser();
   document.getElementById("loginForm").style.display = "block";
   document.getElementById("logoContainer").children[0].style.display = "block";
   document.getElementById("signUpDiv").style.display = "block";
   document.getElementById("legalTermsSection").style.display = "block";
}
